<template>
  <div class="admin-edit-smart-button">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Edit Smart Button</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- /Page Header -->

    <!-- Is Loading -->
    <div v-if="isLoadingCombined" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <div class="form" v-if="!isLoadingCombined">
      <a-form-item label="Button Type">
        <a-select
          disabled
          size="large"
          style="width: 100%"
          v-model="smartButtonLocal.formFactorId"
        >
          <a-select-option
            :value="factor.id"
            v-for="factor in triggerFormFactors"
            :key="factor.id"
          >
            {{ factor.displayName }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Display Name">
        <a-input size="large" v-model="smartButtonLocal.displayName"></a-input>
      </a-form-item>

      <a-form-item label="Default Location">
        <a-select
          size="large"
          style="width: 100%"
          v-model="smartButtonLocal.locationId"
        >
          <a-select-option v-for="location in locations" :key="location.id">
            {{ location.displayName }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </div>

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import externalTriggers from '../../../../api/external-triggers';
import LoadingScreen from "../../../../components/LoadingScreen.vue";
const _ = require("lodash");
export default {
  components: { LoadingScreen },

  data() {
    return {
      isSaving: false,
      smartButtonLocal: {},
    };
  },

  created() {
    if (!this.triggerSource) {
      this.$message.info("Smart button not found");
      this.$router.push("/admin/integration/smart-buttons");
    } else {
      this.smartButtonLocal = {
        ...this.triggerSource,
      };

      if (!(this.triggerFormFactors && this.triggerFormFactors.length)) {
        this.loadTriggerFormFactors();
      }
    }
  },

  methods: {
    ...mapActions("adminSmartButtons", {
      loadTriggerFormFactors: "loadTriggerFormFactors",
      updateSmartButtonLocally: "updateSmartButtonLocally"
    }),

    goBack() {
      this.$router.push("/admin/integration/smart-buttons/" + this.triggerSourceId);
    },

    getValidationErrors() {
      let errors = [];
      if (this.smartButtonLocal.displayName.trim().length == 0) {
        errors.push("Please provide a display name");
      }
      if (!this.smartButtonLocal.locationId) {
        errors.push("Please provide a default location");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      let params = {
        newDisplayName: this.smartButtonLocal.displayName,
        newLocationId: this.smartButtonLocal.locationId
      };

      externalTriggers.updateTriggerSource(
        this.smartButtonLocal.uniqueId,
        params
      ).then(() => {
        vm.updateSmartButtonLocally(this.smartButtonLocal);
        vm.$message.success("Smart button updated successfully");
        vm.goBack();
      }).catch(e => {
        console.log(e);
      })
    },
  },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
      locations: "locations",
    }),

    ...mapGetters("adminSmartButtons", {
      triggerSources: "triggerSources",
      isLoadingTriggerFormFactors: "isLoadingTriggerFormFactors",
      triggerFormFactors: "triggerFormFactors",
    }),

    isLoadingCombined() {
      return this.isLoading || this.isLoadingTriggerFormFactors;
    },

    triggerSourceId() {
      return this.$route.params.id;
    },

    triggerSource() {
      return _.find(this.triggerSources, (ts) => {
        return ts.id == this.triggerSourceId;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.admin-edit-smart-button {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>